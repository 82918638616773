var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "b-tooltip",
          rawName: "v-b-tooltip.hover.noninteractive",
          modifiers: { hover: true, noninteractive: true }
        }
      ],
      staticClass: "ic--container",
      class:
        "ic--container__" +
        _vm.variant +
        " ic--container__" +
        (_vm.highlight ? "highlight" : "nohighlight"),
      attrs: { title: _vm.computedTitle }
    },
    [
      _vm.image
        ? _c("i", {
            staticClass: "ic--icon",
            class:
              "ic--icon__" +
              _vm.size +
              " ic--icon__" +
              (_vm.highlight ? "highlight" : "nohighlight") +
              " " +
              (_vm.border ? "ic--icon__border" : "") +
              " " +
              (_vm.transparent ? "ic--icon__transparent" : ""),
            style: "background-image: url(" + _vm.image + ")"
          })
        : _vm._e(),
      _vm.image ? _c("span", { staticClass: "ic--space" }) : _vm._e(),
      _c(
        "span",
        {
          staticClass: "ic--label",
          class: "ic--label__" + (_vm.highlight ? "highlight" : "nohighlight"),
          attrs: { title: _vm.label }
        },
        [
          _vm._t("default", function() {
            return [_vm._v(" " + _vm._s(_vm.label) + " ")]
          })
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }